import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/chateaudemorey/src/components/Layouts/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonInternal = makeShortcode("ButtonInternal");
const ButtonExternal = makeShortcode("ButtonExternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1><strong parentName="h1">{`Château de Morey Charme & Prestige de France`}</strong></h1>
    <h2>{`Une histoire et un avenir`}</h2>
    <h3>{`Rapide présentation`}</h3>
    <p><div parentName="p" {...{
        "className": "gatsby-resp-iframe-wrapper",
        "style": {
          "paddingBottom": "50%",
          "position": "relative",
          "height": "0",
          "overflow": "hidden",
          "marginBottom": "1.0725rem"
        }
      }}>{` `}<div parentName="div"><iframe parentName="div" {...{
            "src": "https://www.youtube.com/embed/h0Wnp2wRXgw",
            "style": {
              "position": "absolute",
              "top": "0",
              "left": "0",
              "width": "100%",
              "height": "100%"
            }
          }}></iframe></div>{` `}</div></p>
    <p>{`Situé en `}<strong parentName="p">{`Lorraine`}</strong>{`, le château de Morey vous accueille dans ces murs depuis 1998. `}</p>
    <p>{`Entre `}<strong parentName="p">{`Nancy et Metz`}</strong>{`, reposez-vous dans une de ses 5 `}<strong parentName="p">{`chambres d’hôtes`}</strong>{` spacieuses, accompagnées de son parc bucolique et de ses activités (mini-golf, piscine, SPA, activité équestre, baby-foot, billard, boulodrome). `}</p>
    <p>{`Une étape d’exception, le château du XVIe siècle, ancienne demeure des `}<strong parentName="p">{`Ducs de Lorraine`}</strong>{`, d’accès facile (5 km de l’A31 sur D44a).
Isolé de la ville tout en étant qu’à 20 minutes de `}<strong parentName="p">{`Nancy`}</strong>{` et 10 minutes de plusieurs restaurants locaux, appréciez un fabuleux moment de douceur dans un calme certain. `}</p>
    <p>{`Le Château est plus précisément localisé dans la commune de Belleau,
à 305 mètres d’altitude ce qui permet de vous offrir une imprenable vue sur la `}<strong parentName="p">{`vallée de la Natagne`}</strong>{` qui étale les charmes de la `}<strong parentName="p">{`Lorraine`}</strong>{`.`}</p>
    <p>{`Vous pouvez également organiser `}<strong parentName="p">{`vos réceptions`}</strong>{` dans une demeure qui reçoit des `}<strong parentName="p">{`mariages, séminaires et autres événements`}</strong>{` depuis une vingtaine d’année. `}</p>
    <p>{`Aujourd’hui, vous recevoir dans ces murs afin que vous passiez un moment d’émerveillement est la priorité du `}<strong parentName="p">{`Château de Morey`}</strong>{`. Un lieu combinant calme et charme créant un séjour vous permettant de vous ressourcer.`}</p>
    <ButtonInternal to="/chambres-dhotes/" mdxType="ButtonInternal">Découvrez nos chambres d'hôtes</ButtonInternal>
    <ButtonInternal to="/location-salles-nancy-metz/" mdxType="ButtonInternal">Découvrez nos locations de salles</ButtonInternal>
    <h2>{`Son histoire`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/28e4fc40eb91a87992ead592da6b2869/6a068/chateau_de_morey.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.39999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABA//EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAFQuWxJ5Ef/xAAaEAACAwEBAAAAAAAAAAAAAAAAAgEDEiEj/9oACAEBAAEFApsfVraIUl5Y1zzP/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/AaF//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BZ//EABwQAAIBBQEAAAAAAAAAAAAAAAABIQIRMTNBof/aAAgBAQAGPwLKLs4iUiaUa/T/xAAeEAEAAgIBBQAAAAAAAAAAAAABABEhQTFRYXGBsf/aAAgBAQABPyG+GoekuOzkiJfgtgGR3qUaLPsz19Kf/9oADAMBAAIAAwAAABDrL//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxCjY//EABcRAAMBAAAAAAAAAAAAAAAAAAABIRH/2gAIAQIBAT8QpU1n/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBgf/aAAgBAQABPxCyNyleNwxd0x9yB1Q6Namro50JSe7FJ9UwNEov/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Château de Morey",
            "title": "Château de Morey",
            "src": "/static/28e4fc40eb91a87992ead592da6b2869/6a068/chateau_de_morey.jpg",
            "srcSet": ["/static/28e4fc40eb91a87992ead592da6b2869/0479a/chateau_de_morey.jpg 250w", "/static/28e4fc40eb91a87992ead592da6b2869/41099/chateau_de_morey.jpg 500w", "/static/28e4fc40eb91a87992ead592da6b2869/6a068/chateau_de_morey.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Château de Morey`}</strong></p>
    <p>{`Dans la `}<strong parentName="p">{`Lorraine du XIIe siècle`}</strong>{` aurait été construit un Château sur les ruines d’un couvent des templiers.
Il aurait été la demeure du Sire de Morey Jean de Toulon à l’époque où la propriété regroupait le château, la ferme et l’église romane du XII.
C’est ensuite la famille Joly qui séparera les trois domaines et l’écrivain Ducrocq qui les succèdera. `}</p>
    <p>{`Victime d’un incendie en 1985, après avoir été abandonné pendant 8 ans, le château fût rénové par la famille Karst qui aujourd’hui mènent l’activité commerciale de celui-ci. `}</p>
    <p>{`Dans la forêt située au dessus du `}<strong parentName="p">{`Château`}</strong>{`, découvrez des vestiges de l’histoire. À pied, à vélo ou à cheval parcourez les sentiers de la forêt pour apercevoir `}<strong parentName="p">{`des anciennes tranchés de la guerre, des galeries secrètes, et autres`}</strong>{`… `}</p>
    <p>{`L’histoire s’étend de près comme de loin autour du `}<strong parentName="p">{`Château de Morey`}</strong>{`.
Et oui la `}<strong parentName="p">{`Lorraine`}</strong>{` est une région qui a un lourd passé. Celle-ci à connu beaucoup de guerres, d’occupations, et des histoires mystérieuses.
Depuis le `}<strong parentName="p">{`Moyen-Âge`}</strong>{` à maintenant, en passant par de nombreuses époques, vous pourrez découvrir les secrets de cette région.`}</p>
    <p>{`Au coeur de la `}<strong parentName="p">{`Meurthe et Moselle`}</strong>{`, le château tend à continuer de fructifier son histoire, en vous accompagnant dans vos moments intimes, célébrer vos mariages, accompagnés vos soirées…
Un vaste jardin soutenu par une muraille de plus de 5 mètres de hauteur. Marronniers, cèdre du LIBAN, tilleuls, hêtres pourpres, chênes, érable et une marre aux Canards créant un espace unique au `}<strong parentName="p">{`Château de Morey`}</strong>{`.`}</p>
    <ButtonInternal to="/newsletter/" mdxType="ButtonInternal">Rejoignez notre newsletter</ButtonInternal>
    <h2>{`Sa vie actuelle`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e5f290bd73f3916fcf58131988aa1728/e895b/tourelle2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.8%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAf/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABd2IxndIjg//EABoQAAMAAwEAAAAAAAAAAAAAAAABEQIDEiH/2gAIAQEAAQUC6wZPKa3R9FZ//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bh//EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8BuGP/xAAaEAACAgMAAAAAAAAAAAAAAAAAEQEhEBIx/9oACAEBAAY/AnQ8vabOn//EABsQAAMBAAMBAAAAAAAAAAAAAAABESExUWHh/9oACAEBAAE/IcNv70a2+jhlCyR4SzZhpfM//9oADAMBAAIAAwAAABBXD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QEnLG/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAECAQE/EEHY/8QAHBABAAICAwEAAAAAAAAAAAAAAQARITFBUWGh/9oACAEBAAE/EE4lFLofUaiFGavtz3LAG/TmX8FoGw9iwxkZ0Br7MU7p/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Château de Morey",
            "title": "Château de Morey",
            "src": "/static/e5f290bd73f3916fcf58131988aa1728/a2510/tourelle2.jpg",
            "srcSet": ["/static/e5f290bd73f3916fcf58131988aa1728/0479a/tourelle2.jpg 250w", "/static/e5f290bd73f3916fcf58131988aa1728/41099/tourelle2.jpg 500w", "/static/e5f290bd73f3916fcf58131988aa1728/a2510/tourelle2.jpg 1000w", "/static/e5f290bd73f3916fcf58131988aa1728/c58a3/tourelle2.jpg 1500w", "/static/e5f290bd73f3916fcf58131988aa1728/3acf0/tourelle2.jpg 2000w", "/static/e5f290bd73f3916fcf58131988aa1728/e895b/tourelle2.jpg 2302w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Château de Morey`}</strong></p>
    <p>{`Aujourd’hui le `}<strong parentName="p">{`Château de Morey`}</strong>{` continue à vous accueillir en ses lieux, et met tout en oeuvre pour que vous puissiez passer un moment inoubliable. De l’accueil à ses services, vivez un séjour authentique et spectaculaire dans les murs en pierres de la demeure.`}</p>
    <p>{`Le domaine est donc autant un lieu pour se reposer comme il peut se transformer en `}<strong parentName="p">{`lieu festif`}</strong>{`. En plus de la réception de vos événements, nous organisons régulièrement des `}<strong parentName="p">{`soirées ou brunch`}</strong>{` pour le plaisir de partager des bons moments avec vous. `}</p>
    <p>{`Le `}<strong parentName="p">{`Château`}</strong>{` se voit toujours en cours d’amélioration et a pour projet de refaire sa toiture, pour cette démarche une cagnotte a été crée afin de pouvoir permettre cette réalisation.`}</p>
    <ButtonExternal href="https://www.leetchi.com/c/projets-de-chateau-de-morey-22970336" mdxType="ButtonExternal">Participez à notre cagnotte</ButtonExternal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      